  .infos ul {
    list-style: none;
    padding: 0;
  }

  .profcard {
    display:flex;
    flex-direction:row;
    margin-left: 35px;
    padding-left: 20px;
    background-color: rgba(255, 255, 255, .5);
    width: 730px;
    box-shadow: 0 0 30px rgba(0, 0, 0, .15);
    transform-style: preserve-3d;
    overflow: hidden;
    align-items: center;
    align-content: center;
  }

  .arc {
    height: 102px;
    width: 102px;
    align-self: baseline;
  }
  
  .profimg {
    cursor: pointer;
    width: 140px;
    height: 164px;
    margin: 20px 0;
    border-radius:20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .infos {
    margin-left: 15px;
    width: 63.8%;
    text-align: justify;
  }
  
  .nameprof h2 {
    font-size: 20px;
    width: fit-content;
    cursor: pointer;
  }
  
  .text2{
    font-size: 14px;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: pre-line;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .view {
    width: 120px;
    padding: 7px;
    border: 1px solid #5DB0D8;
    border-radius: 5px;
    background: transparent;
    font-weight: bold;
    cursor: pointer;
    transition: all .25s linear;
  }
  
  .view:hover {
    background-color: #5DB0D8;
    color: #FFF;
  }

  .line{
    width: 730px;
    margin: 30px 0 10px 35px;
  }
  

  @media (max-width: 1115px){
    .profcard{
      width: 600px;
    }
    .line{
      width: 600px;
    }
    .infos {
      width: 55.6%;
    }
}
@media (max-width: 985px){
  .profcard{
    margin-left: 0px;
  }
  .line{
    margin-left: 0px;
  }
}

@media (max-width: 950px){
  .profcard{
    width: 450px;
    margin-left: 35px;
    padding-left: 15px;
}

.arc{
  width: 94px;
  height: 94px;
}
.line{
  width: 450px;
  margin-left: 35px;
  }
  .nameprof h2 {
    font-size: 18px;
  }
  .text2{
    font-size: 12px;
  }
  .view {
    font-size: 13px;
    width: 110px;
  }
  .infos {
    margin-left: 10px;
    width: 47%;
  }
  .profimg {
    width: 126.341463px;
    height: 148px;
    margin: 15px 0;
  }
}

@media (max-width: 763px){
  .profcard{
    margin-left: 0px;
  }
  .line{
    margin-left: 0px;
  }
}
@media (max-width: 500px){
  .profcard{
    width: 320px;
}
.arc {
  display: none;
}
  .line{
    width: 320px;
}
.infos {
  margin-left: 8px;
  width: 56%;
}
.nameprof h2 {
  font-size: 16px;
}
.text2{
  font-size: 12px;
}
.view {
  font-size: 12px;
  width: 90px;
  padding: 5px;
}
.profimg {
  width: 105px;
  height: 123px;
}
}

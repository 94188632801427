/* body {
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

::-webkit-scrollbar {
  display: none;
  overflow-x: "hidden";
}

/* custom scrollbar */
/* ::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
} */

/* body::-webkit-scrollbar {
  background-color: #fff;
  width: 1.17vw;
}
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}
body::-webkit-scrollbar-button {
  display:none;
} */
.App {
  font-family: "Rubik";
  text-align: center;
  color: black;
  cursor: default;
}


.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.section_main{
    background-color: #afafaf;
    padding-bottom: 143px;
}

.exp{
    width: 280px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6vh;
    color: black;
    border: 3px solid #000000;
    background: transparent;
    font-family: Rubik;
    font-weight: 400;
    font-size: 20px;
    line-height: 4.15vh;
    border-radius: 37px;
    padding: 15px 45px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
}

@media (max-width: 768px){
    .section_main{
        padding-top: 1px;
    }

    .exp{
        width: 250px;
        font-size: 18px;
        line-height: 4.15vh;
        padding: 10px 30px;
    }
}

@media (max-width: 600px){
    .exp{
        width: 210px;
        font-size: 16px;
        line-height: 4.15vh;
    border: 2.4px solid #000000;
    padding: 10px 30px;
    }
}

@media (max-width: 375px){
    .exp{
        width: 190px;
        font-size: 14px;
        line-height: 4.15vh;
        padding: 7px 28px;
    }
}


.tnc-note{
    color: black;
    width: 330px;
    font-weight: 500;
    font-size: 14px;
}

p.tnc-note{
    margin: 0 0 20px 20px;
}

.signup-button{
    border-radius: 31.55px;
    background: #3e6ae1;
    font-family: Rubik;
    font-size: 17px;
    font-weight: 500;
    color: white;
    border: none;
    height: 45px;
  	width: 400px;
    padding: 9.3px 16.456px;
    cursor: pointer;
	text-align: center;
}

.passerror{
    position: absolute;
    color: red;
    font-weight: 400;
    font-size: 15px;
    top: 145px;
    width:360px;
    margin:0;
    padding:0;
    float:right;
    justify-content: flex-end;
}
.passerror2{
    position: absolute;
    color: red;
    font-weight: 400;
    font-size: 15px;
    top: 145px;
    width:360px;
    margin:0;
    padding:0;
    float:right;
    justify-content: flex-end;
}

@media  screen and (max-width: 425px) {  
    .signup-button{
        width: 300px;
    }

    .tnc-note{
        width: 240px;
        font-size: 13px;
    }
    .passerror{
        font-size: 14px;
        top: 125px;
        justify-content: flex-start;
        width:fit-content;
        padding-left: 20px;
    }
    .passerror2{
        font-size: 14px;
        top: 125px;
        width:300px;
        padding-left: 20px;
    }
}

@media  screen and (max-width: 320px) {
    .signup-button{
        width: 260px;
        font-size: 15px;
        height: 45px;
    }

    .tnc-note{
        width: 220px;
    }

    .passerror{
        font-size: 13px;
        top: 120px;
        justify-content: flex-start;
        width:fit-content;
        padding-left: 40px;
    }
    .passerror2{
        font-size: 14px;
        top: 120    px;
        width:260px;
    }
}
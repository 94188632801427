.text{
    margin: 21px 30px 0 20px;
    width: 30.3%;
    height: auto;
}

.main{
    margin-top: -15px;
    background-color: white;
    height: fit-content;
    width: 100%;
    border: 1px solid;
}

@media (max-width: 1145px){
    .text{
        width: 31.9%;
        margin: 21px 30px 0 20px;
    }
}
@media (max-width: 1000px){
    .text{
        margin: 21px 0 0 12px;
        width: 32.7%;
    }
}
@media (max-width: 950px){
    .text{
        margin: 30px 0 0 0px;
        width: 55%;
        padding: 0 20px;
    }
}

@media (max-width: 815px){
    .text{
        margin: 21px 0 0 0px;
        width: 100%;
    }
    .main{
        width: 100%;
    }
}
@media (max-width: 450px){
    .text{
        padding: 0 10px;
    }
}

.ad1{
    background-color: #7A1B1B;
    /* width: 29.28vw; */
    height: 120px;
    margin-top: 10px;
    /* position: absolute; */
    /* margin-top: 83vh; */
}

.ad2{
    background-color: #7A1B1B;
    /* position: absolute; */
    /* width: 29.28vw; */
    margin-top: 10px;
    height: 120px;
    /* margin-top: 109.3vh; */
}

.ad1 p{
    text-align: center;
    margin-top: 9.13vh;
    font-size: larger;
}

.ad2 p{
    text-align: center;
    margin-top: 9.13vh;
    font-size: larger;
}
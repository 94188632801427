.footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 27px;
    font-family: Rubik;
    background: white;
}

.link{
    display: flex;
    flex-direction: row;
}
.link p{
    margin: 0 17px 0 17px;
    font-weight: 400;
    font-size: 17px;
    text-align: center;
    color: black;
    margin-bottom: 0;
    cursor: pointer;
}
.link a{
    text-decoration: none;
}

.social-icon{
    width: 38px;
    height: 38px; 
    margin: auto 8px;
    border: 2.4px solid;
    border-radius: 9px;
    color: #1A1A1A;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    cursor: pointer;
}


.social{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.social a{
    cursor: default;
}
.social img{
    position: relative;
    width: 38px;
    height: 38px; 
    margin: auto 8px;
    cursor: pointer;
}

.copyright{
    display: inline;
    font-size: 13px;
    margin: 0 0 0 30px;
}

@media (max-width: 1250px){
    .link p{
        margin-right: 0 15px 0 15px;
    }
    .social img{
        margin: auto 6px; 
    }
}

@media (max-width: 1190px){
    .footer{
        padding: 12px 10px;
    }
    .link p{
        margin: 0 10px 0 10px;
        font-size: 15px;
    }
    .social img{
        width: 32px;
        height: 32px; 
        margin: auto 6px; 
    }
    .copyright{
        margin: 0 0 0 20px;
    }
}

@media (max-width: 975px){
    .footer{
        display: block;
        padding: 12px 27px;
        font-family: Rubik;
    }
    
    .link{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    .link p{
        margin: 0 17px 0 17px;
        font-weight: 400;
        font-size: 17px;
        text-align: center;
        color: black;
        margin-bottom: 0;
    }
    .link a{
        text-decoration: none;
    }
    .social{
        display: contents;
        margin-top: 15px;
    }
    .social img{
        position: relative;
        width: 36px;
        height: 36px; 
        margin: auto 8px;
        margin-top: 10px;
    }
    
    .copyright{
        font-size: 13px;
        margin-right: 17px;
        margin-top: 18px;
        float: right;
    }
}

@media (max-width: 768px){
    .footer{
        padding: 12px 10px;
    }
    .link p{
        margin: 0 8px 0 8px;
        font-size: 14px;
    }
    .social img{
        position: relative;
        width: 32px;
        height: 32px; 
        margin: 10px 6px 0 6px;
    }
    
    .copyright{
        font-size: 12px;       
        margin-top: 17px;
        margin-right: 8px;
    }
}

@media (max-width: 530px){
    .footer{
        padding: 12px 10px;
    }
    .link p{
        margin: 0 6px 0 6px;
        font-size: 12px;
    }
    .social img{
        position: relative;
        width: 28px;
        height: 28px; 
        margin: 10px 4px 0 4px;
    }
    .copyright{
        font-size: 10px;
        margin-top: 17px;
        margin-right: 6px;
    }
}

@media (max-width: 450px){
    .footer{
        padding: 12px 5px;
    }
    .link p{
        margin: 0 4px 0 4px;
        font-size: 11px;
    }
    .social img{
        position: relative;
        width: 24px;
        height: 24px; 
        margin: 10px 3px 0 3px;
    }
    .copyright{
        font-size: 9px;
        margin-top: 17px;
        margin-right: 4px;
    }
}

@media (max-width: 385px){
    .footer{
        padding: 12px 12px;
    }
    .link p{
        margin: 0 3.5px 0 3.5px;
        font-size: 10px;
    }
    .social img{
        position: relative;
        width: 20px;
        height: 20px; 
        margin: 10px 3px 0 3px;
    }
    .copyright{
        font-size: 9px;
        margin-top: 14px;
        margin-right: 3.5px;
    }
}

@media (max-width: 362px){
    .link p{
        margin: 0 3px 0 3px;
        font-size: 9px;
    }
    .social img{
        width: 18px;
        height: 18px; 
    }
    .copyright{
        font-size: 8px;
    }
}
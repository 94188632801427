.rpv-default-layout__sidebar--opened{
    width: 16rem;
}

.rpv-default-layout__sidebar-content--opened{
    font-size: 16px;
    padding: 7px;
}
.coverbtn{
    font-size: 14px;
    border: 1px solid;
    padding: 4px 30px;
    background-color: white;
}

.coverbtn:hover{
    cursor: pointer;
}

.coverimg{
    width: fit-content;
}

.nofile{
    width: 149px;
    height: 198px;
}

.title-field{
    width: 530px;
    height: 50px;
    margin-bottom: 30px;
    background: rgb(255, 255, 255);
    box-sizing: border-box;
    border: 2px solid rgb(0, 0, 0);
    padding: 0 5px;
}
.desc-field{
    width: 530px;
    resize: none;
    height: 200px;
    margin-bottom: 30px;
    padding: 0 5px;
    background: rgb(255, 255, 255);
    border: 2px solid rgb(0, 0, 0);
    box-sizing: border-box;
}

.select{
    width: 240px;
    height: 50px;
    margin-bottom: 60px;
    color: black;
    border: 2px solid black;
    font-size: 15px;
    padding-left: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-repeat: no-repeat;
    background-position-y: center;
  background-position-x:
    calc(100% - 10px);
  background-size:10px 10px;
  background-repeat: no-repeat;
}

.btn-sec{
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* margin-bottom: 30px; */
}

@media (max-width: 795px){
    .title-field{
        width: 370px;
    }
    .desc-field{
        width: 370px;
    }
    .select{
        width: 215px;
    }
}

@media (max-width: 635px){
    .coverbtn{
        font-size: 12px;
        padding: 4px 20px;
    }
    .title-field{
        width: 300px;
    }
    .desc-field{
        width: 300px;
    }
    .select{
        width: 190px;
        font-size: 13px;
        padding-left: 5px;
        background-position-x:
        calc(100% - 8px);
    }
    .btn-sec{
        width: 300px;
    }
}

@media (max-width: 575px){
    .title-field{
        width: 350px;
    }
    .desc-field{
        width: 350px;
    }
    .select{
        width: 350px;
        font-size: 13px;
        padding-left: 10px;
        background-position-x:
        calc(100% - 10px);
    }
    .btn-sec{
        width: 350px;
    }
}

@media (max-width: 400px){
    .title-field{
        width: 320px;
    }
    .desc-field{
        width: 320px;
    }
    .select{
        width: 320px;
        font-size: 13px;
        padding-left: 10px;
        background-position-x:
        calc(100% - 10px);
    }
    .btn-sec{
        width: 320px;
    }
}
.head{
    text-align: center;
    margin-bottom: 30px;
}
.intro p{
    margin-bottom: 30px;
    padding: 0px 50px;
    font-size: 25px;
}
.head span{
    font-weight: 900;
}

.showcase{
    margin: 120px auto 50px auto;
    font-family: "Rubik";
}

.showcase-img{
    display: flex;
    justify-content: center;
    align-self: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    width: 100%;
}

.img-1{
    background-image: url('./assets/images/illustrations/doc.png');
    height:500px;
    background-position: center;
}
.img-2{
    background-image: url('./assets/images/illustrations/one.jpg');
    height: 548px;
    transform: scaleX(-1);
}
.img-3{
    background-image: url('./assets/images/illustrations/three.jpg');
    height: 548px;
}
.img-4{
    background-image: url('./assets/images/illustrations/two.jpg');
    height: 548px;
    transform: scaleX(-1);
}

.sp-r{
    font-size: 35px;
    text-align: right;
    margin-right: 150px;
    margin-bottom: 0;
}

.sp-l{
    font-size: 35px;
    text-align: left;
    margin-left: 150px;
    margin-bottom: 0;
}

.sp-r p, .sp-l p{
    margin: 0;
}

@media (max-width: 1024px){
    .sp-r{
        margin-right: 100px;
    }
    
    .sp-l{
        margin-left: 100px;
    }
}



@media (max-width: 992px){
    .showcase-img{
        height:400px;
    }

    .sp-r{
        margin-right: 70px;
        font-size: 30px;
    }
    
    .sp-l{
        margin-left: 70px;
        font-size: 30px;
    }
    .intro p{
        text-align: center;
    }
}


@media (max-width: 768px){
    .intro p{
        font-size: 22px;
    }
    .showcase-img{
        height:300px;
    }
    .sp-r{
        margin-right: 30px;
        font-size: 26px;
    }
    
    .sp-l{
        margin-left: 30px;
        font-size: 26px;
    }
    .showcase{
        margin: 80px auto 50px auto;
    }
}

@media (max-width: 600px){
    .sp-r{
        margin-right: 10px;
        font-size: 20px;
    }
    
    .sp-l{
        margin-left: 10px;
        font-size: 20px;
    }
}

@media (max-width: 425px){
    .intro p{
        font-size: 18px;
    }
    .showcase-img{
    height:200px;
    }
    .sp-r{
        margin-right: 12px;
        font-size: 16px;
    }
    
    .sp-l{
        margin-left: 12px;
        font-size: 16px;
    }
}



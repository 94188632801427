.searchdiv{
    height: 400px;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    z-index: 9999;
    margin-top: 66px;
}

.MuiCard-root::-webkit-scrollbar{
    display: none;
}


.searchdiv .MuiButton-root{
    display: block;
    text-align: left;
    text-transform: none;
    height: 53px;
}


.searchdiv .MuiButton-root .MuiButton-label{
    font-size: 14px;
    font-weight: normal;
    width: fit-content;
}
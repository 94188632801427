.card {
    border-radius: 8px;
    height: 350px;
    width: 300px;
    margin: 20px 15px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 0 20px -10px black;
    overflow: hidden;
    border: 0;
}

.card-blur {
    height: 100%;
    width: calc(100% + 1px);
    background-color: black;
    opacity: 0;
    transition: opacity 0.15s ease-in;
}

.card:hover .card-blur {
    opacity: 0.4;
}

.tc-footer {
    z-index: 1;
    position: absolute;
    height: 110px;
    width: 100%;
    bottom: 0;
}

/* add till svg#curve'x' where x is number of team members */
/* svg#curve1 {
    position: absolute;
    fill: white;
    opacity: 0.9;
    left: 0;
    bottom: 0;
    width: 300px;
    height: 350px;
    z-index: -1;
} */

.connections {
    height: 30px;
    width: 300px;
    margin: auto;
    display: flex;
}

.connection {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: white;
    /* display: inline-block; */
    /* padding: 5px; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;
    transform: translateY(200px);
    
    transition: transform 1s cubic-bezier(.46, 1.48, .18, .81);
}

.card:hover .connection {
    transform: translateY(0px);
}

.tc-info {
    background-color: white;
    opacity: 0.85;
    height: 100px;
	font-family: 'Rubik';
    padding-left: 20px;
    cursor: default;
    transform: translateY(250px);
    transition: transform 1s cubic-bezier(.31,1.21,.64,1.02);
}

.card:hover .tc-info {
    transform: translateY(15px);
}

.name {
    font-size: 22px;
    font-weight: bolder;
    padding-top: 5px;
    cursor: default;
    font-weight: bold;
}

.job {
    cursor: default;
    color: #A3A3A3;
    font-size: 15px;
}

/* .connection.insta {
    margin-left: 20px;
}

.connection.twitter {
    transition-delay: 0.06s;
}

.connection.whatsapp {
    transition-delay: 0.12s;
} */

.connection.linkedin {
    margin-left: 20px;
    transition-delay: 0.06s;
}


@media (max-width: 650px) {
    .card {
        height: 280px;
        width: 240px;
    }
    .name{
        font-size: 19px;
    }
    .job{
        font-size: 13px;
    }
    .connections {
        width: 240px;
    }
    .connection {
        margin-right: 15px;
    }
    .connection.linkedin{
        margin-left: 15px;
    }
}
.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Rubik;
}

form{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.comlogo{
    margin-left: 32px;
    margin-top: 20px;
}

.comlogo img{
    height: 31.227px;
    width: 168px;
    cursor: pointer;
}

.heading{
    font-size: 40px;
    font-weight: 600;
    color: black;
    margin-bottom: 32.175px;
    margin-top: 43px;
}

.input{
    background-color: #d6d3d3;
    width: 400px;
    height: 48px;
    border: none;
    border-radius: 31.55px;
    padding: 14.5px 10px 17.5px 20px;
    margin: 5.5px 0;
    margin-bottom: 22px;
}

input{
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out
}

.input:focus {
    color: #000;
    background: #fff;
    outline: 1
}

.icon{
    font-size: 20px;
    position: absolute;
    padding-top: 13px;
    margin-left: -40px;
}

form label{
    font-size: 17px;
    font-family: Rubik;
    font-weight: 600;
    color: #595555;
    margin-left: 20px;
}

.signin-button{
    border-radius: 31.55px;
    background: #3e6ae1;
    font-family: Rubik;
    font-size: 17px;
    font-weight: 500;
    color: white;
    border: none;
    height: 45px;
  	width: 400px;
    padding: 9.3px 16.456px;
    margin-bottom: 10px;
    cursor: pointer;
	text-align: center;
}

.google-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 31.55px;
    text-align: center;
    background: #3e6ae1;
    font-size: 17px;
    font-weight: 500;
    color: white;
    border: none;
    height: 45px;
    width: 400px;
    padding: 9.3px 16.456px 9.3px 0.5px;
    cursor: pointer;
}

.google-button img{
    float: left;
    height: 43px;
    width: 43px;
}

.forgotpassword{
    font-weight: 500;
    cursor: pointer;
    float: right;
    font-size: 15px;
    cursor: pointer;
    margin: 10px 0 0 265px;
}

.or{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 400px;
}

.or-text{
    font-size: 17px;
    font-weight: 600;
    color: #595555;
}

.hr-left{
    width: 150px;
    margin-right: 15px;
    margin-left: 15px;
}

.create-account-button{
    border-radius: 31.55px;
    border: 3px solid black;
    width: 400px;
    height: 48px;
    background: transparent;
    color: #4b4949;
    position: relative;
    top: 40px;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 70px;
}

.error{
    position: absolute;
    color: red;
    font-weight: 400;
    font-size: 15px;
    top: 150px;
    display: flex;
    justify-content: flex-start;
    width:360px;
}

.requiredError{
    display: flex;
    position: absolute;
    color: red;
    font-weight: 400;
    font-size: 15px;
    top: 145px;
    width:400px;
    margin:0;
    padding:0;
    float:right;
    justify-content: flex-end;
}

.continue{
    font-weight: normal;
    position: relative;
    left: 75px;
}


@media  screen and (max-width: 768px) {
    .comlogo img{
        height: 28.625px;
        width: 155px;
    }
    .comlogo{
        text-align: center;
    }
}

@media  screen and (max-width: 425px) {
    .comlogo img{
        height: 23.235px;
        width: 125px;
    }
    .comlogo{
        margin-left: 32px;
        margin-top: 10px;
    }
    .heading{
        font-size: 30px;
    }
    
    .input{
        width: 300px;
    }
    
    .signin-button{
        width: 300px;
    }

    .continue{
        left: 35px;
    }
    
    .google-button{
        width: 300px;
    }
    
    .forgotpassword{
        margin: 10px 0 0px 170px;
    }

    .or{
        width: 300px;
    }
    
    .or-text{
        font-size: 15px;
        font-weight: 600;
        color: #595555;
    }
    
    .hr-left{
        width: 120px;
        margin-top: 17.875px;
        margin-right: 15px;
        margin-left: 15px;
    }
    .create-account-button{
        border: 3px solid black;
        width: 300px;
        height: 48px;
        top: 30px;
    }
    .error{
        font-size: 14px;
        top: 125px;
        justify-content: flex-start;
        width:260px;
    }
    
    .requiredError{
        font-size: 14px;
        top: 125px;
    width:300px;
    }
}

@media  screen and (max-width: 320px) {
    .comlogo{
        text-align: center;
        margin-left: 0px;
        margin-top: 10px;
    }

    .heading{
        font-size: 25px;
    }

    label{
        font-size: 15px;
    }

    .input{
        width: 260px;
        height: 45px;
    }
    
    .signin-button{
        width: 260px;
        font-size: 15px;
        height: 45px;
        width: 260px;
    }

    .google-button{
        font-size: 15px;
        height: 45px;
        width: 260px;
    }
    
    .continue{
        left: 20px;
    }
    
    .forgotpassword{
        margin: 10px 0 0px 125px;
        font-size: 13px;
    }

    .or{
        width: 260px;
    }
    
    .or-text{
        font-size: 15px;
        font-weight: 600;
        color: #595555;
    }
    
    .hr-left{
        width: 100px;
        margin-top: 15px;
        margin-right: 15px;
        margin-left: 15px;
    }
    .create-account-button{
        border: 3px solid black;
        width: 260px;
        height: 45px;
        top: 20px;
        font-size: 15px;
    }
    .error{
        font-size: 13px;
        top: 120px;
        justify-content: flex-start;
        width:fit-content;
    }
    
    .requiredError{
        font-size: 13px;
        top: 115px;
        width:260px;
    }
}
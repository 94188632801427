/* p:hover{
    cursor: pointer;
} */

a:hover{
    cursor: pointer;
}


.send{
    background-color: white;
    width: 24.7vw;
    padding: 8px 5px 8px 10px;
    display: flex;
    border: 1px solid;
    border-radius: 15px;
    align-items: center;
}

.send img:hover{
    cursor: pointer;
}

.send-message{
    display: flex;
    height: auto;
    justify-content: space-evenly;
    margin-bottom: 10px;
}

.textarea{
    border: none;
    resize: none;
    /* width: 20vw; */
    width: 100%;
    margin-left: 1vw;
}

.textarea:focus{
    border: none;
    outline: none;
  }


.messages{
    height: 400px;
    overflow-y: scroll;
}


.messages::-webkit-scrollbar{
    width: 0.6vw;
}

.messages::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    border-radius: 8px;
  }
  
.messages::-webkit-scrollbar-thumb {
    background-color: rgb(185, 185, 185);
    outline: 1px solid white;
}

/* top */

.messageCard{
    padding: 0px 10px 0px;
    width:fit-content;
    height: fit-content;
    word-wrap: break-word;
    display: flex;
    align-items: center;
}


.userCard{
    text-align: left !important;
    background-color: white !important;
    color: black !important;
    font-weight: 400;
    border: 1px solid;
    border-radius: 20px;
    padding:3px 5px;
    width: 24vw;
} 

.couserCard{
    background-color: white !important;
    color: black !important;
    font-weight: 400;
    border: 1px solid;
    border-radius: 20px;
    padding:3px 5px;
    width: 21vw;
}

.picker-container{
    position: absolute;
    margin: -367px 0px 0px 0px;
}

.details{
    text-align: right;
    margin-left: 62px;
    width: 20.8vw;
    margin-top: 1px;
}

.details p{
    font-size: 11px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.details a{
    margin-top: 0.676px;
}

.details img{
    height: 11.5px;
    margin: 3.25px 7.48px;
}



@media (max-width: 950px){
    .userCard{
        min-width: 30vw;
    } 
    .couserCard{
        min-width: 30vw;
    }
    .send{
        width: 42vw;
    }
    .details{
        width: 29.8vw;
    }
}
@media (max-width: 815px){
    .userCard{
        min-width: 60vw;
    } 
    
    .couserCard{
        min-width: 60vw;
    }
    .send{
        width: 80vw;
    }
    .details{
        width: 59.8vw;
    }
}
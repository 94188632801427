

.bgImg {
    background-image: url("./assets/images/hero-banner/bnr.png");
    background-repeat: repeat-x;
    align-self: center;
    display: flex;
    justify-content: center;
    background-position: center center;
    background-size: cover;
    background-repeat: repeat-x;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-x: hidden;
    height: 350px;
    font-family: "Rubik",sans-serif;
    margin-top: 20px;
  }

.sec1{
  display: flex;
  align-self: center;
  justify-content: center;
}

.tag-h1{
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0.01em;
    text-align: center;
    margin: 0;
    width: 572.3px;
  }

  @media screen and (max-width: 768px) {
    .tag-h1{
        font-size: 28px;
      }
  }

  @media screen and (max-width: 600px) {
    .tag-h1{
        font-size: 24px;
        width: 402px;
        line-height:35px;
      }

      .bgImg {
        height: 200px;
        background-size: 900px;
      }
  }

  @media screen and (max-width: 475px) {
    .tag-h1{
        font-size: 20px;
        line-height: 26px;
        width: 295px;
      }
  }

  @media screen and (max-width: 356px) {
    .tag-h1{
        font-size: 18px;
        line-height: 25px;
      }
  }
.MuiDialog-root{
    display: flex;
    justify-content: center;
    align-items: center;
}

.firstimg{
    width: 480px;
    height: 480px;
    z-index: 5;
}

.input-user{
    color: black;
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }

.dot{
    margin: 10px 0;
}

@media (max-width: 565px){
    .firstimg{
        width: 300px;
        height: 300px;
    }
    svg.MuiSvgIcon-root.dot{
        width: 20px;
        height: 20px;
    }
}
@media (max-width: 385px){
    .firstimg{
        width: 250px;
        height: 250px;
    }
    svg.MuiSvgIcon-root.dot{
        width: 16px;
        height: 16px;
    }
}
.navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center ;
    height: 77.76px;
    margin-top: 8px;
}

/* brand name */
.brand{
    margin-left: 32px;
}
.brand img{
    height: 31.227px;
    width: 168px;
}

/* logo */
.logo img {
    height: 4.35em;
    width: 4.375em;
}

/* signup Button */
.btn1{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid black !important;
    width: 168px;
    height: 42px;
    margin-right: 32px;
    color: black;
    background: transparent;
    border-radius: 31.35px !important;
    cursor: pointer;
    font-size: 20px;
    font-weight: 400;
    font-family: Rubik;
    text-align: center;
    padding: 6px 12px;
}

@media (max-width: 600px) {
    .brand{
        padding: 0;
        margin-left: 10px;
    }
    .brand img{
        height: 22.557px;
        width: 120px;
    }
    .logo img {
        height: 3.35em;
        width: 3.375em;
    }
    .btn1{
        border: 2.4px solid black !important;
        height: 32px;
        font-size: 15px;
        width: 120px;
        margin-right: 10px;
    }
}